/* You can add global styles to this file, and also import other style files */
/* --------------------------------Start Navbar----------------------- */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("assets/css/bootstrap.css");
@import url("assets/css/animated-4-1-1.min.css");


*,
*::after,
*::before {
   box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: none;
}

.cdk-overlay-backdrop {
  background: rgb(255 255 255 / 45%) !important;
  backdrop-filter: blur(4px);
}

body {
  font-family: "Maven Pro", sans-serif !important;
  line-height: 1.8;
}

/* .mat-h2, .mat-title, .mat-typography{
  font-family: 'Maven Pro', sans-serif !important;
} */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: "Maven Pro", sans-serif !important;
}

a:hover {
  text-decoration: inherit;
  cursor: pointer;
}



.form-group {
  margin-bottom: 5px !important;
}

.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}

.navbar {
  position: fixed;
  width: 100%;
  background: #fff !important;
  color: #000;
  opacity: 1 !important;
  z-index: 1;
  position: relative;
}

.logo img {
  width: calc(100% - 20%);
}

@media screen and (max-width: 767px) {
  .logo img {
    width: calc(100% - 45%) !important;
  }
}

@media screen and (max-width: 1000px) {
  .logo img {
    width: 100%;
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
}

.menu-items {
  order: 2;
  display: flex;
  margin-bottom: 0;
}

.menu-items .nav_Consult {
  margin-top: -8px;
}

.menu-items li {
  list-style: none;
  margin-left: 1.5rem;
}

.navbar a {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  padding-bottom: 5px;
}

.dropdown {
  padding-bottom: 10px;
}
.dropdown:hover .dropdown-nav {
  opacity: 1;
  display: block;
  transform: translateY(0);
}
.dropdown-nav {
  display: none;
  transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1),
    opacity 0.3s ease-in-out;
  transform: translateY(-4rem);
  list-style: none;
  margin: 0;
  padding: 10px 5px;
  position: fixed;
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
  width: 100%;
  left: 0;
  top: 60px;
}

.dropdown-nav li {
  float: left;
}

.dropdown-nav li a {
  color: #fff;
}

@media (max-width: 768px) {
  .nav_Consult a::after {
    top: 7px !important;
    right: 13px !important;
    width: 22px !important;
    height: 22px !important;
  }
  .navbar {
    opacity: 0.95;
  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  /* .navbar-container input[type="checkbox"]{
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    } */

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #333;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 100px;
    background: #fff;
    height: 100vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
    overflow: scroll;
  }

  .navbar .menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}

@media (max-width: 500px) {
  .navbar-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
}

/* -------------------------------End Navbar-------------------- */

/* -----------------------Banner Slider------------------- */
/* Slider */
.carousel-item {
  /* height: 584px; */
  background-repeat: no-repeat;
  /* background-position: center center; */
  background-size: cover;
}

@media screen and (max-width:767px) {
  .carousel-item {
    height: 547px;
  }
}

@media screen and (width:540px) {
  .carousel-item {
    height: 565px;
  }
}

@media screen and (max-width: 415px) {
  .carousel-item {
    height: 470px;
  }
}

@media screen and (max-width: 282px) {
  .carousel-item {
    height: 400px;
  }
}

.text-center {
    text-align: center!important;
}
.position-relative {
    position: relative!important;
}
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}
@media screen and (max-width: 394px){
  .carousel-item {
      height: 460px;
  }
}

@media screen and (max-width: 376px) {
  .carousel-item {
      height: 445px;
  }

}

.Banner-caption {
  padding-top: 100px;
  /* max-width: 450px; */
  width: calc(100% - 63%);
  text-align: left;
}

.Banner-caption h2 {
  font-size: 55px;
  color: #131b28;
  font-weight: bold;
  line-height: 60px;
  /* text-align: left; */
}
.Banner-caption h3 {
  font-size: 40px;
  color: #131b28;
  font-weight: 600;
}

.resi_color {
  color: #ff305d;
}

.Banner-caption p {
  font-size: 17px;
  color: #000;
  line-height: 25px;
  font-weight: 500;
  max-width: 600px;
  padding-top: 8px;
}

.banner_button {
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
  color: #fff !important;
  font-size: 18px;
  padding: 8px 35px;
  border-radius: 60px;
  margin-top: 5px;
  display: inline-block;
}

@media only screen and (max-width: 1374px) {
  .Banner-caption h2 {
      font-size: 50px;
      color: #131b28;
      font-weight: bold;
      line-height: 60px;
  }
}
@media only screen and (max-width: 1249px) {
  .Banner-caption h2 {
      font-size: 45px;
      color: #131b28;
      font-weight: bold;
      line-height: 60px;
  }
}
@media only screen and (max-width: 1146px) {
  .Banner-caption h2 {
    font-size: 22px;
    color: #131b28;
    font-weight: bold;
    line-height: 42px;
}
}

@media only screen and (max-width: 1000px) {
  .Banner-caption {
    padding-top: 108px;
    max-width: 450px;
  }
  .Banner-caption h2 {
    font-size: 19px;
    color: #131b28;
    font-weight: bold;
    line-height: normal;
}
}

@media only screen and (max-width: 870px) {
  .Banner-caption {
    padding-top: 61px;
    max-width: 450px;
  }
}

.slider-section {
  position: relative;
}
/* .slider-section::after {
  content: "";
  position: absolute;
  background: url('assets/images/banner-angle.png') no-repeat;
  width: 446px;
  height: 360px;
  right: 0;
  bottom: 0;
  z-index: 0;
} */

.slider_buttons {
  width: 200px;
  position: absolute;
  right: 0px;
  z-index: 11;
}

.slider_buttons .carousel-control-next {
  top: -65px;
  background: #fe2f7c;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  left: 70px;
  opacity: 0.8;
}

.slider_buttons .carousel-control-next:hover {
  background: #b831a8;
}

.slider_buttons .carousel-control-prev:hover {
  background: #b831a8;
}

.slider_buttons .carousel-control-prev {
  top: -65px;
  background: #fe2f7c;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  left: 20px;
  opacity: 0.8;
}

/* -----------------------End Banner Slider------------------- */

/* <!-- -------------------------Services Section Start----------------- --> */

.sscp40 {
  padding: 50px 0px;
}

.section_heading h2 {
  font-size: 40px;
  color: #131b28;
  text-align: center;
  font-weight: 600;
  letter-spacing: -1px;
}

.section_heading p {
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  color: #000;
  max-width: 600px;
  margin: 0 auto;
}

.service_item {
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.13);
  border-radius: 25px;
  padding: 25px 0px;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
}

.service_icon {
  margin: 0 auto;
  display: table;
  width: 60px;
}
.service_item:hover .service_icon {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes wobble-vertical {
  16.65% {
    transform: translateY(20px);
  }
  33.3% {
    transform: translateY(-12px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
.service_item .service_name {
  font-size: 14px;
  color: #000;
  display: block;
  padding-top: 10px;
  text-align: center;
}

/* <!-- -------------------------Services Section End----------------- --> */

/* <!-- -------------------------Our Technologies Section Start----------------- --> */

.auto_center {
  margin: 0 auto;
  display: table !important;
}

.resi_light_bg {
  background: #ffeeee;
}

.Consult_btn a {
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
  color: #fff !important;
  font-size: 18px;
  padding: 12px 50px 12px 35px;
  border-radius: 60px;
  margin-top: 15px;
  display: inline-block;
  position: relative;
}

.nav_Consult a:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

.nav_Consult a:hover {
  box-shadow: 0px 0px 19px 15px rgb(254 48 105 / 35%) !important;
  transition: 0.6s;
}

.Consult_btn a:hover {
  box-shadow: 0px 0px 19px 15px rgb(254 48 105 / 35%) !important;
  transition: 0.6s;
}

.Consult_btn a::after {
  content: "";
  position: absolute;
  top: 12px;
  right: 25px;
  background: url(assets/images/send.webp) no-repeat;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  width: 20px;
  height: 20px;
  display: inline-block;
  background-position: 0px;
  background-size: 100%;
}

/* <!-- -------------------------Our Technologies Section End----------------- --> */

/* <!-- -------------------------Work Counter Section Star----------------- --> */

.counter_Parallax {
  background: url("https://images.prismic.io/kurmishadi/48a4a4ef-72eb-4b72-a111-01f7d2121596_counter_parallax.jpg?auto=compress,format") no-repeat center;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-attachment: fixed;
  background-size: cover;
}

.counter_icon {
  width: 50px;
  margin-bottom: 30px;
}

.counter {
  font-size: 50px;
  color: #fff;
  font-weight: 500;
}

.counter_plus {
  font-size: 50px;
  color: #fff;
  font-weight: 500;
}

.counter-desc {
  font-size: 20px;
  color: #fff;
}

.counter_item {
  position: relative;
}

.counter_item::after {
  content: "";
  position: absolute;
  background: url("assets/images/counter-line.webp");
  right: 0;
  top: 0;
  height: 149px;
  width: 2px;
}
.counter_item:last-child::after {
  background: none !important;
}

.counter_slogan h3 {
  font-size: 40px;
  line-height: 50px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -1px;
}

.counter_slogan h3 span {
  font-size: 60px;
}

.trusted_section {
  background: #fff;
  padding: 20px;
  border-top-left-radius: 12px;
  position: relative;
}

.trusted_section::after {
  content: "";
  position: absolute;
  background: #fff;
  width: 50px;
  height: 100%;
  top: 0;
  /* right: -71px; */
  right: calc(100% - 107%);
}

@media screen and (min-width: 1000px) {
  .trusted_section::after {
    content: "";
    position: absolute;
    background: #fff;
    width: 47px;
    height: 100%;
    top: 0;
    /* right: -71px; */
    right: calc(100% - 107%);
}
}
@media screen and (min-width: 1500px) {
  .trusted_section::after {
    content: "";
    position: absolute;
    background: #fff;
    width: 68px !important;
    height: 100%;
    top: 0;
    /* right: -71px; */
    right: calc(100% - 107%);
}
}

.trusted_section h4 {
  font-size: 35px;
  line-height: 40px;
  color: #131b28;
  text-transform: uppercase;
  font-weight: 600;
}

.trusted_section p {
  font-size: 15px;
  line-height: 24px;
  color: #000;
  padding-top: 10px;
}

/* <!-- -------------------------Work Counter Section End----------------- --> */

/* <!-- -------------------------Form Parallax Section Start----------------- --> */

.Form_Parallax {
    background: linear-gradient(
      rgb(3 101 211 / 42%) 100%,
      rgb(0 0 0 / 0%) 100%
    ),
    url("https://images.prismic.io/kurmishadi/38fd26f5-cef4-47c4-9ca7-dd8d73ab2730__%281%29.png?auto=compress,format") no-repeat center;
    /* /assets/images/our-teams/ (1).svg */

  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-attachment: fixed;
  background-size: cover;
}

.slogan img {
  width: 120px;
  margin: 0 auto 10px;
  display: table;
  animation: spin 5s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}

.Form_Parallax .slogan h3 {
  font-size: 55px;
  color: #fff;
  text-align: center;
  line-height: 65px;
  font-weight: 600;
  max-width: 900px;
  margin: 0 auto;
}

.Form_Parallax .slogan h3 span {
  color: #fe3066;
}

.consolt_form_box {
  background-color: rgb(0 0 0 / 40%);
  padding: 20px 50px;
  max-width: 550px;
  margin: 0 auto;
  border-radius: 8px;
}

.consolt_form_box .heading {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.form-group input {
  width: 98%;
  padding: 5px 5px;
  border-radius: 3px;
  border: 0px;
  margin: 0 1%;
}

.form-group textarea {
  width: 99%;
  padding: 5px 5px;
  border-radius: 3px;
  border: 0px;
  box-sizing: border-box;
  margin: 0px 0.5%;
}

input::placeholder {
  font-size: 14px;
  color: #333333;
}

textarea::placeholder {
  font-size: 14px;
  color: #333333;
}

.form_button {
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
  color: #fff !important;
  font-size: 18px;
  padding: 15px 50px 15px 35px;
  border-radius: 4px;
  position: relative;
  border: 0px;
  text-transform: uppercase;
  margin: 0 auto;
  display: table;
  cursor: pointer;
}

.form_button::after {
  content: "";
  position: absolute;
  top: 12px;
  right: 25px;
  background: url(assets/images/send.webp) no-repeat;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  width: 20px;
  height: 20px;
  display: inline-block;
  background-position: 0px;
  background-size: 100%;
}

.form_button:hover {
  box-shadow: 0px 0px 19px 15px rgba(254, 48, 105, 0.35) !important;
  transition: 0.6s;
}

/* <!-- -------------------------Form Parallax Section End----------------- --> */

/* <!-- -------------------------Team Members Section Start----------------- --> */

#team-members .owl-prev {
  display: none;
}

#team-members .owl-next {
  display: none;
}

.member_item .name {
  font-size: 17px;
  color: #000;
  text-align: center;
  display: block;
  margin-top: 10px;
  font-weight: 500;
}

.member_item .position {
  font-size: 14px;
  color: #555555;
  text-align: center;
  display: block;
}

.member_feedback {
  position: absolute;
  top: 0px;
  left: 0px;
  background: #1f2024;
  padding: 15px;
  height: 287px;
  display: none;
  border-radius: 8px;
}

.member_feedback p {
  font-size: 14px;
  line-height: 20px !important;
  color: #fff;
  text-align: justify;
  z-index: 11;
  position: relative;
}

.crousel-slide:hover .member_feedback {
  display: block;
  transition: 0.8s;
}

.member_feedback::before {
  content: "";
  position: absolute;
  background-image: url("assets/images/logo-icon.webp");
  width: 180px;
  height: 235px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: 15px;
  opacity: 0.4;
  left: 30px;
}

#team-members .owl-pagination {
  display: none;
}

/* <!-- -------------------------Team Members Section End----------------- --> */

/* <!-- -------------------------Client Feedbacks Section Start----------------- --> */

.feedback_sec {
  background: url("assets/images/testimonials.webp") no-repeat center;
  background-size: auto;
  background-size: auto;
  background-size: cover;
  min-height: 714px;
}

.feedback_heart {
  width: 45px;
  transform: rotate(18deg);
}
.Feedback_title h1 {
  font-size: 35px;
  color: #674186;
  font-weight: bold;
  padding-bottom: 8px;
  font-family: "FilsonSoft-Medium";
}

.Feedback_title h2 {
  font-size: 20px;
  color: #674186;
  padding-bottom: 50px;
  font-family: "FilsonSoft-Medium";
}

.testimonial {
  margin: 0 15px;
}
.testimonial .content {
  border: 1px solid rgba(255, 255, 255, 0.28);
  padding: 20px;
  border-radius: 18px;
  position: relative;
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.05);
  margin: 0 15px;
  text-align: center;
  min-height: 200px;
  background-color: rgba(255, 255, 255, 0.18);
}
.testimonial .content::after {
  content: "";
  position: absolute;
  left: 30px;
  bottom: -21px;
  border-top: 20px solid rgba(255, 255, 255, 0.18);
  border-right: 26px solid transparent;
  border-bottom: none;
}

.testimonial .description {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 26px;
  color: #fff;
  padding-bottom: 20px;
}
.testimonial-pic {
  float: left;
}
.feedback_star {
  float: right;
  padding-top: 30px;
}
.testimonial .testimonial-review {
  margin: 30px 0 0 15px;
  float: left;
}
.testimonial .testimonial-title {
  font-size: 16px;
  text-transform: capitalize;
  margin: 0;
  color: #fff;
  font-weight: 500;
}
.testimonial > .testimonial-review span {
  color: white;
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
}
.testimonial .feedback_icon {
  padding: 0;
  margin: 0;
}
.testimonial .feedback_icon > li {
  list-style: none;
  display: inline-block;
  margin-right: 0;
}

.feedback_icon i {
  background: #00b67b;
  color: #fff;
  font-size: 14px;
  padding: 3px;
  border-radius: 3px;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  background: #fff;
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 18px !important;
  height: 18px !important;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791;
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #ffc6f4;
}
.owl-theme .owl-controls .owl-page span {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.38);
}
.owl-theme .owl-controls .owl-page span {
  width: 10px;
  height: 10px;
  margin: 5px 6px;
}

/* <!-- -------------------------Client Feedbacks Section End----------------- --> */

/* -----------------------------------Footer Start------------------------------ */

.footer_bg {
  background: #1f2024;
  padding: 40px 0px;
}

.footer_bg::before {
  content: "";
  background: url("assets/images/logo-icon.webp") no-repeat;
  background-size: auto;
  position: absolute;
  width: 250px;
  height: 400px;
  background-size: 100%;
  left: 3%;
  opacity: 0.2;
}

.footer_link_colom .heading {
  font-size: 17px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer_link_colom ul li {
  list-style-type: none;
}

.footer_link_colom ul li a {
  font-size: 14px;
  color: #9c9ca5;
  position: relative;
  padding-bottom: 8px;
  display: inline-block;
}

.footer_link_colom ul li a:hover {
  color: #fff;
  transition: 0.6s;
}

.footer_adrs {
  display: flex;
}

.footer_adrs::before {
  content: "";
  background: url("assets/images/pin.webp") no-repeat;
  position: absolute;
  width: 18px;
  height: 23px;
  left: -4px;
  top: 0;
}

.footer_mail::before {
  content: "";
  background: url("assets/images/email-grey.webp") no-repeat;
  position: absolute;
  width: 20px;
  height: 19px;
  left: -6px;
  top: 0;
}

.footer_call::before {
  content: "";
  background: url("assets/images/call.webp") no-repeat;
  position: absolute;
  width: 25px;
  height: 25px;
  left: -6px;
  top: 0;
}

.footer_bottom {
  position: relative;
  margin-top: 20px;
}

.footer_bottom::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: #323439;
  top: -15px;
}

.copyright {
  font-size: 14px;
  color: #696c77;
}

.footer_terms_privacy a {
  font-size: 14px;
  color: #696c77;
  text-decoration: underline;
}

.footer_terms_privacy a:hover {
  color: #fff;
  text-decoration: none;
}

/* -----------------------------------Footer End------------------------------ */

/* <!-- -------------------------Owl crousel Start----------------- --> */

.owl-theme .owl-controls .owl-page {
  display: inline-block;
}

.owl-pagination {
  margin: 20px auto 0;
  display: table;
}

.owl-theme .owl-controls {
  margin: 0 auto;
}
.crousel-slide {
  margin: 0px 10px;
  padding-top: 1px;
  position: relative;
  overflow: hidden;
}

.crousel-slide .post-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: -12px 15px 8px 15px;
  margin-left: -10px;
}
.crousel-slide img {
  border-radius: 8px;
  width: 100%;
}

.crousel-slide .post-title a:hover {
  text-decoration: none;
  color: #3498db;
}
.crousel-slide .post-description {
  line-height: 24px;
  color: #808080;
  margin-bottom: 25px;
}

.owl-controls .owl-buttons {
  text-align: center;
  margin-top: 20px;
}
.owl-controls .owl-buttons .owl-prev {
  background: #fff;
  position: absolute;
  top: 41%;
  left: -7px;
  height: 50px;
  width: 50px;
  padding-top: 2px;
  border-radius: 50px;
  transition: background 0.5s ease 0s;
}
.owl-controls .owl-buttons .owl-next {
  background: #fff;
  position: absolute;
  top: 41%;
  right: -7px;
  height: 50px;
  width: 50px;
  padding-top: 2px;
  border-radius: 50px;
  transition: background 0.5s ease 0s;
}
.owl-controls .owl-buttons .owl-prev:after,
.owl-controls .owl-buttons .owl-next:after {
  content: "\f104";
  font-family: FontAwesome;
  color: #333;
  font-size: 30px;
}
.owl-controls .owl-buttons .owl-next:after {
  content: "\f105";
}
@media only screen and (max-width: 1280px) {
  .crousel-slide .post-content {
    padding: 0px 15px 25px 15px;
  }
}

.food_categories img {
  width: 100%;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transform: translateZ(0);
}

.food_categories img:hover {
  transform: scale(1.05);
}

.food_categories_section {
  background: url(https://api.asm.skype.com/v1/objects/0-sa-d7-b716393395aceebb37e9dbc976d7f6ac/views/imgpsh_fullsize_anim)
    no-repeat center center;
  background-size: cover;
}

/* <!-- -------------------------Owl crousel End----------------- --> */

/* <!-- ------------------Page Heading Start------------- --> */

.page_heading {
  background-image: linear-gradient(to right, #fc4f67, #7e30cb);
  padding: 15px;
  margin-top: 20px;
}

@media screen and (max-width: 1000px) {
@media screen and (min-width: 766px) {
  .page_heading {
    background-image: linear-gradient(to right, #fc4f67, #7e30cb);
    padding: 15px;
    margin-top: 59px;
  }
}
}

.page_heading h2 {
  font-size: 35px;
  color: #fff;
  font-weight: 600;
  margin: 0 !important;
  padding: 8px 0 !important;
  text-transform: uppercase;
}

/* <!-- ------------------Page Heading End------------- --> */

/* ----------------------About us Page Start-------------------- */

.about_menu_holder {
  border-bottom: 3px solid #ff305d;
  background: #1f2024;
  z-index: 1;
}

.about_sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.about_page_menu {
  overflow: hidden;
  padding: 20px 0px;
}
.about_page_menu ul li {
  list-style-type: none;
  float: left;
  padding: 0px 25px;
}

.about_page_menu ul li a {
  font-size: 20px;
  color: #fff !important;
  font-weight: 500;
}

.ab_sec_heading {
  font-size: 30px;
  color: #131b28;
  font-weight: 600;
  letter-spacing: -1px;
  padding-bottom: 20px;
}

.intro_holder p {
  font-size: 16px;
  line-height: 25px;
  color: #000;
  padding-bottom: 10px;
}

/* .about_gallery {
  overflow: hidden;
  max-width: 1000px;
} */

.about_gallery .gallery-item {
  float: left;
  width: 31.5%;
  margin: 0.5% 0.5%;
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.about_gallery .gallery-item img {
  width: 100%;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.gallery-item img:hover {
  transform: scale(1.1);
}

#our_leaders .owl-buttons {
  display: none;
}

#our_leaders .owl-pagination {
  display: none;
}

#jobs {
  background: #f7f8fa;
  padding: 40px 0px;
}

.abt_job_heading h2 {
  font-size: 30px;
  color: #131b28;
  font-weight: 600;
  letter-spacing: -1px;
}

.abt_job_heading h2 span {
  color: #fe3066;
}

.abt_job_heading p {
  font-size: 16px;
  line-height: 25px;
  color: #000;
  padding-bottom: 20px;
}

.job_item {
  padding: 25px;
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
  max-width: 900px;
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}

.job_item .job_name {
  font-size: 25px;
  color: #082253;
  font-weight: 600;
  letter-spacing: -1px;
}

.job_action {
  overflow: hidden;
}

.job_action .item {
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  font-size: 14px;
  color: #1f2024;
  background-size: 20px 20px;
  background-position: 2px 0px;
  padding-left: 30px;
  float: left;
  margin-right: 15px;
}

.experience {
  background: url("/src/assets/images/briefcase.webp") no-repeat;
}

.worktime {
  background: url("/src/assets/images/briefcase.webp") no-repeat;
}

.job_location {
  background: url("/src/assets/images/location.webp") no-repeat;
}

.job_description {
  font-size: 14px;
  color: #5e5e5e;
  padding-top: 15px;
}

.rs_small_btn a {
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
  color: #fff !important;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 3px;
  margin-top: 5px;
  display: inline-block;
  position: relative;
  font-weight: 500;
  border: 1px solid transparent;
}

.rs_small_btn a:hover {
  transition: 0.6s;
  background: #fff;
  color: #fe3066 !important;
  border: 1px solid #fe3066;
}

/* ----------------------About us Page End-------------------- */

/* <!-- -------------------------Payment Section Start----------------- --> */

.payment_holder {
  background: #f0f4f8;
  padding: 40px 0px;
}

.payment_card {
  max-width: 700px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  padding: 30px;
}

.payment_item {
  width: 32%;
  margin-right: 0.5%;
  float: left;
}

.payment_item:last-child {
  margin-right: 0%;
}

.payment_item label {
  display: block;
  border-radius: 4px;
  padding: 12px 0;
  border: 1px solid #f9cdf0;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center !important;
}

.payment_item label img {
  height: 45px;
}

.checkbox-custom,
.radio-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  background: rebeccapurple;
  color: #fff;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label::before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #93de43;
  font-size: 12px;
  line-height: 13px;
}

.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
  outline: 1px solid #ddd; /* focus style */
}

.payment_item input[type="radio"]:checked + label {
  animation-name: blink;
  animation-duration: 1s;
  border-color: #259bf7;
  border-width: 1px;
  color: #674186;
}

/* <!-- -------------------------Payment Section End----------------- --> */

/* <!-- -------------------------Career-pop-up-form start----------------- --> */

.submit-button {
  background-image: linear-gradient(to right, #ff2f87, #fe3061);
  color: #fff !important;
  border-radius: 4px;
  font-size: 18px;
  padding: 10px 56px;
  position: relative;
  border: 0px;
  text-transform: uppercase;
  margin: 0 auto;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0px 10px 13px 0px rgb(0 0 0 / 30%);
  width: 100%;
}
.file-submit {
  color: #000 !important;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 0px;
  position: relative;
  border: 0px;
  text-transform: uppercase;
  margin: 12px auto !important;
  cursor: pointer;
}
.form-control-job:focus-visible {
  color: #495057;
  background-color: #fff;
  border-color: #ff2f81;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 207 226);
}

.ab_sec_heading-pop {
  font-size: 30px;
  color: #131b28;
  font-weight: 600;
  letter-spacing: -1px;
  margin: 14px 0px;
}

#wrapper {
  width: 100px;
  margin: 0 auto;

  padding: 40px;

  border-radius: 15px;
  background-clip: padding-box;
  text-align: center;
}
.form-control-job {
  height: 23px;
  border-radius: 5px;
  font-size: 16px;
  color: #818181;
  padding: 23px 20px !important;
  border: 1px solid #dfdfdf;
  width: 100%;
  margin-bottom: 20px;
}

.button {
  font-size: 13px;
  padding: 5px 10px;
  border: 1px solid #aaa;
  background-color: #eee;
  background-image: linear-gradient(to top, #fff, #f0f0f0);
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  color: #666;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 3 68 / 28%);
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
}
.overlay.light {
  background: rgba(255, 255, 255, 0.5);
}
.overlay .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 75px auto;
  padding: 40px;
  background: #fff;
  max-width: 540px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
}
.light .popup {
  border-color: #aaa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}
.popup h2 {
  margin-top: 0;
  color: #666;
  font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
  color: #666;
}
.popup .close:hover {
  opacity: 1;
}
.popup .content {
  max-height: 400px;
  overflow: auto;
}
.popup p {
  margin: 0 0 1em;
}
.popup p:last-child {
  margin: 0;
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  background: #ff006c;
}

.mat-dialog-container {
  padding: 0 !important;
}
.mat-dialog-container .popup {
  box-shadow: none;
  margin: 0 auto !important;
}
.cdk-overlay-constainer #cdk-overlay-0 {
  max-width: 90vw !important;
}
.mat-dialog-container .popup > .row {
  margin-right: 0 !important;
}

.contact_post .nav_Consult a::after {
  height: 0;
  width: 0;
}

.contact_post ::after {
  display: none !important;
}

.contact_post .nav_Consult a {
  padding: 8px 20px 8px 20px !important;
}

/* <!-- -------------------------Work Counter Section Star----------------- --> */

.platform_item {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.platform_item img {
  max-width: 100%;
  transition: transform 0.2s ease-in-out;
  border-radius: 8px;
}

.platform_item img:hover {
  transform: scale(1.1);
}

.platform_name {
  font-size: 17px;
  color: #131b28;
  font-weight: 500;
  text-align: center;
  margin-top: 17px;
}

/* <!-- -------------------------Work Counter Section End----------------- --> */
/* <!-- -------------------------Career-pop-up-form End----------------- --> */
@media screen and (max-width: 600px) {
  .popup img {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .footer_logo img {
    width: 80%;
  }

  .more_links {
    min-height: 100vh;
  }

  .cdk-overlay-pane {
    max-width: 95% !important;
  }

  .sidebar {
    padding: 30px !important;
  }

  .payment_card {
    padding: 30px 15px 50px;
  }
  .payment_item {
    width: 49% !important;
  }

  .payment_item label img {
    height: 30px !important;
  }

  .page_heading h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .section_heading h2 {
    font-size: 25px;
  }
  .section_heading p {
    font-size: 15px;
  }

  .counter_Parallax .mt-5 {
    margin: 0px !important;
  }

  .counter_item {
    margin-bottom: 25px;
  }

  .counter {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
  }

  .counter-desc {
    font-size: 13px;
    color: #fff;
    font-weight: 600;
  }

  .counter_slogan h3 {
    font-size: 25px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 20px;
  }

  .counter_slogan h3 {
    font-size: 30px;
    font-weight: bold;
  }

  .counter_slogan h3 span {
    font-size: 45px;
  }

  .trusted_section {
    border-radius: 12px;
    margin-bottom: 30px;
  }
  .trusted_section::after {
    content: inherit;
    display: none;
  }

  .trusted_section h4 {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
  }

  .trusted_section p {
    font-size: 14px;
    text-align: center;
  }

  .slogan img {
    width: 80px;
  }
  .Form_Parallax .slogan h3 {
    font-size: 30px;
    letter-spacing: -1px;
    line-height: 36px;
  }

  .consolt_form_box .heading {
    font-size: 18px;
    font-weight: 600;
  }

  .footer_bg .container .mt-5 {
    margin-top: 0px !important;
  }
  .footer_link_colom .heading {
    margin-top: 20px;
    font-size: 14px;
  }

  .footer_link_colom ul li {
    list-style-type: none;
    float: left;
    margin-right: 12px;
  }

  .footer_link_colom ul li a {
    font-size: 12px;
  }

  .footer_bg .container .pl-0 {
    padding-left: 15px !important;
  }

  .footer_bg .container .mt-5 {
    margin-top: 0px;
  }

  .social_links {
    float: none !important;
    margin-top: 20px;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
}

.feedback_sec .slick-dots li {
  display: none !important;
}

.bannerComponent .slick-dots li button::before {
  color: black !important;
  font-size: 9px;
}
.bannerComponent .slick-dots {
  bottom: 24px !important;
}

@media screen and (min-width : 768px) and (max-width : 950px) {
  .bannerComponent .slick-dots {
    bottom: -25px !important;
  }
}

@media screen and (max-width : 280px) {
  .bannerComponent .slick-dots {
    bottom: -8px !important;
  }
}
@media screen and (min-width : 1200px) and (max-width : 1280px) {
  .bannerComponent .slick-dots {
    bottom: 10px !important;
  }
}
.bannerComponent .slick-dots li {
  margin: 0 !important;
}

/* Stripe Pay  */

/* Variables */
.stripe-pay * {
  box-sizing: border-box;
}

.stripe-pay body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

.stripe-pay .hidden {
  display: none;
}

.stripe-pay #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.stripe-pay #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripe-pay button {
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
  width: 100%;
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  padding: 8px 50px 8px 35px;
  border-radius: 3px;
  margin-top: 5px;
  display: block;
  position: relative;
  border: 0px;
  margin-top: 20px;
  box-shadow: 0px 10px 13px 0px rgb(0 0 0 / 30%);
  cursor: pointer;
}
.stripe-pay button:hover {
  filter: contrast(115%);
}
.stripe-pay button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.stripe-pay .spinner,
.stripe-pay .spinner:before,
.stripe-pay .spinner:after {
  border-radius: 50%;
}
.stripe-pay .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.stripe-pay .spinner:before,
.stripe-pay .spinner:after {
  position: absolute;
  content: "";
}
.stripe-pay .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.stripe-pay .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
.logo::after {
  content: initial !important;
}
.logo {
  padding-top: 18px;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .stripe-pay form {
    width: 80vw;
    min-width: initial;
  }
}

/*
user Gallery
*/

.mat-mdc-tab  {
  border-top-left-radius: 10px;
  color: #000 !important;
  border-top-right-radius: 10px;
}
.mat-mdc-tab-labels{
  border-bottom: 1px solid #80808047;
}
.mdc-tab--active.mat-mdc-tab  {
  opacity: 1;
  font-size: 1.2em !important;
  font-weight: 500 !important;
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
  transition: 0.2s;
  font-size: 16px;
}

.mdc-tab--active.mat-mdc-tab .mdc-tab__text-label {
  color: #fff !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-image: linear-gradient(to right, #ff2f8b, #fe3061);
}


.mat-mdc-tab .mdc-tab-indicator__content--underline{
  border-color:linear-gradient(to right, #ff2f8b, #fe3061);
  width: 0px !important;
}

@media (min-width: 1360px) and (max-width: 1500px) {
  .container {
    min-width: 1240px !important;
  }
}


@media (min-width: 1500px) and (max-width: 1700px) {
  .container {
    min-width: 1400px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1900px) {
  .container {
    min-width: 1500px !important;
  }
}
@media (min-width: 1900px) and (max-width: 2100px) {
  .container {
    min-width: 1600px !important;
  }
}
@media (min-width: 2100px) and (max-width: 2300px) {
  .container {
    min-width: 1700px !important;
  }
}

@media (min-width: 2300px) and (max-width: 2500px) {
  .container {
    min-width: 1800px !important;
  }
}

@media (max-width: 1000px) {
    .trusted_site {
      bottom: 91px !important;
    }
    @media (min-width: 768px) {
      .trusted_site {
        bottom: 101px !important;
      }
    }
}

.ngx-mat-tel-input-overlay-backdrop.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0 !important;
}

.cdk-overlay-connected-position-bounding-box .mat-menu-panel {
  width: 400px !important;
  max-height: 400px !important;
  max-width: 400px !important;
}

.ngx-floating .ngx-mat-tel-input-container input:not(.country-search) {
display: block;
width: 100%;
height: calc(1.5em + 0.75rem + 2px);
padding: 0.375rem 0.75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: 0.25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding-left: 92px;
}

.ngx-floating .ngx-mat-tel-input-container input:not(.country-search):focus {
  color: #495057;
  background-color: #fff;
  border-color: #ff2f81 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 207 226);
}

.ngx-floating .ngx-mat-tel-input-container input:not(.country-search).mat-input-element {
  caret-color: #000 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
